<template>

    <div class="col-12 col-sm-3 col-md-4 col-lg-4">

        <div class="card profile-card">
           
            <div class="profile-head mb-2">
                <div class="patient-search">
                    <AsyncSelect
                        placeholder="Patient Name, ID, Mobile No"
                        v-model="patient"
                        :api-service="fetchContactProfiles"
                        :additional-query="{ type: 'patient'}"
                        :format-label="formatPatientLabel"
                        :reduce="option => option.id"
                        @option:selected="onSelectPatient"
                        :additional-option="additionalOption"
                    />
                </div>

                <div class="patient-action">
                    <button 
                        class="btn btn-primary"
                        @click="openPatientAddModal"
                        >
                        <i class="fas fa-plus"></i>
                        Patient
                    </button>
                </div>
            </div>

            <div class="profile-head">

                <div class="patient-search">
                    <label for="colFormLabel" class="col-form-label">Patient ID</label>
                    <input
                        v-model="patientSerialNo"
                        name="serial"
                        type="number"
                        class="form-control"
                    />
                </div>

                <div class="single-search-action">
                    <button 
                        class="btn btn-primary"
                        @click="searchPatientById"
                    >
                        Go
                    </button>
                </div>
            </div>
                
        </div>

        <div class="row" v-if="userInfo">
            <div class="col-md-12">

                <div class="position-relative">
                    <div class="profile-img-container d-flex align-items-center">
                        <div class="position-relative profile-img">
                            <img :src="userInfo.photo" class="rounded img-fluid" alt="No image found">
                        </div>
                    </div>
                </div>

            </div> 
            
            <div class="col-md-12 mt-2 mb-0 m-1 ">
                <table class="table profile-tale">
                    <tr>
                        <td>Full name</td>
                        <td> : </td>
                        <td> {{ userInfo.full_name }}</td>
                    </tr>
                    <tr>
                        <td>Age & Sex</td>
                        <td> : </td>
                        <td class="capitalize"> {{ calculateAge(userInfo.birthday) }} {{ userInfo.gender ? ' | ' + userInfo.gender : '' }}</td>
                    </tr>
                    <tr>
                        <td>Mobile</td>
                        <td> : </td>
                        <td class="capitalize"> {{ userInfo.mobile_no }}</td>
                    </tr>
                    <tr>
                        <td>Serial</td>
                        <td> : </td>
                        <td class="capitalize"> {{ userInfo.serial_no }}</td>
                    </tr>
                </table>
            </div>

        </div>
        
        <AddPatientModal 
            v-if="$store.state.isModalOpenTwo"
            :doctors="doctors"
            @onCreateProfile="onCreateProfile"
        />

        <Loader v-if="isLoader" />
        
    </div>

</template>

<script setup>
import handleHospital from '@/services/modules/hospital'
import AddPatientModal from '@/components/molecule/company/hospital/AddPatientModal'
import { inject, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import handleContact from '@/services/modules/contact';
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import { useAsyncDropdownHelper } from "@/services/utils/asyncDropdownHelper";
import Loader from "@/components/atom/LoaderComponent";

const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchContactProfiles } = handleContact();
const $store = useStore();
const props = defineProps(['patients', 'doctors', 'profileId']);
const emit = defineEmits(['update:profileId']);
const $route = useRoute();
const userInfo = ref(null);
const additionalOption = ref([]);
const patient = ref(null);
const patientSerialNo = ref(null);
const isLoader = ref(false);
const showError = inject('showError');

const {
    fetchPatientDetails,
    fetchPatientDetailsBySerial,
    calculateAge
} = handleHospital()

function getQuery() {
    let query = `?company_id=${$route.params.companyId}`;
    return query;
}

const onCreateProfile = async(profileInfo) => {
    userInfo.value = profileInfo;
    let newPatient = {
        id: profileInfo.id,
        name: profileInfo.full_name
    };
    additionalOption.value.push(newPatient)
    emit('update:profileId', profileInfo.id);
    await getPatientDetails(profileInfo.id);
}

const onSelectPatient = async (value) => {
    emit('update:profileId', value.id);
    await getPatientDetails(value.id);
}

const searchPatientById = async () => {
    if(!patientSerialNo.value) return;

    userInfo.value = null
    patient.value = null
    isLoader.value = true;
    const query = getQuery();

    await fetchPatientDetailsBySerial(query, patientSerialNo.value)
        .then((res) => {
            
            if (!res.status) {
                return showError(res.message)
            }
            
            userInfo.value = res.data
            patient.value = {
                id: userInfo.value.id,
                name: userInfo.value.full_name
            }

            emit('update:profileId', userInfo.value.id);
        })
    
    isLoader.value = false;
}

const getPatientDetails = async (id) => {
    const query = getQuery();
    await fetchPatientDetails(query, id)
        .then((res) => {
            if(!res.status) return;
            
            userInfo.value = res.data
            patient.value = {
                id: userInfo.value.id,
                name: userInfo.value.full_name
            }
        })
}

const openPatientAddModal = () => {
    $store.state.isModalOpenTwo = true
}

onMounted(async() => {
    if(props.profileId){
        await getPatientDetails(props.profileId);
    }
})
</script>
<style scoped>
.single-search-action{
    margin-top: 11%;
}
.patient-search{
    width: 76%;
}
.patient-action button {
    padding: 11px 5px;
}
.admission-history hr{
    margin: 4px;
}
.profile-tale > :not(caption) > * > * {
    padding: 0.5rem 0.5rem !important;
}
.profile-tale{
    font-weight: bold;
}

.profile-tale > :not(caption) > * > * {
    border-bottom-width: 0;
}

.profile-tale tr {
    border: none;
}
.profile-img {
    text-align: center;
}

.profile-img img {
    width: 50%;
}
.profile-card {
    margin-top: 10px;
    margin-left: 9px;
}
.profile-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profile-card .input-group{
    width: 90%;
}
</style>